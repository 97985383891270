import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Fun Focus Software" />
    <div className="center">
      <h3>Fun Focus Software</h3>
      <h4>We make software for creatives. More coming soon.</h4>
    </div>
  </Layout>
)

export default IndexPage
